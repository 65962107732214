<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<h5>Empty Page</h5>
				{{mainData}}
				
				<DataTable
					:value="mainData"
					:resizableColumns="true" columnResizeMode="expand" showGridlines
					:scrollable="true"
					scrollHeight="400px"
					:loading="loading"
					scrollDirection="both">
					<Column field="urunKodu" header="Id" style="width:200px">
					</Column>
					<Column field="urun" header="Id" style="width:200px">
						<template #body="slotProps">
							<div class="text">{{slotProps.data.urun}}</div>
						</template>
					</Column>
					<Column field="urunKodu" header="Id" style="width:200px"></Column>
					<Column field="urun" header="Id" style="width:200px"></Column>
					<Column field="urunKodu" header="Id" style="width:200px"></Column>
					<Column field="urun" header="Id" style="width:200px"></Column>
					<Column field="urunKodu" header="Id" style="width:200px"></Column>
					<Column field="urun" header="Id" style="width:200px"></Column>
					<Column field="urunKodu" header="Id" style="width:200px"></Column>
					<Column field="urun" header="Id" style="width:200px"></Column>
					<Column field="urunKodu" header="Id" style="width:200px"></Column>
					<Column field="urun" header="Id" style="width:200px"></Column>
					<Column field="urunKodu" header="Id" style="width:200px"></Column>
					<Column field="urun" header="Id" style="width:200px"></Column>
					<Column field="urunKodu" header="Id" style="width:200px"></Column>
					<Column field="urun" header="Id" style="width:200px"></Column>

				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
import CrmService from "../service/CrmService";

export default {
	data() {
		return {
			crmService: null,
			mainData: {}
		}
	},
	created() {
		this.crmService = new CrmService();

		console.log('created');

		this.OnLoad();
	},
	methods: {
		async OnLoad(){
			let loader = this.$loading.show({
				container: this.$refs.smsContainer
			});

			try {
				debugger;
				let data = await this.crmService.getTeklifById('6d253bf7-3d5c-ed11-9b90-000c2920f782');
				if (data.jsonData.length > 0) {
					this.mainData = data.teklifUrunleriData;
				}
			} catch (error) {
				console.log(error);
				//this.$router.push('/');
			}
			finally {
				loader.hide();
			}
		},
	}
}
</script>

<style scoped>
	.text {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>
